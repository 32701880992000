module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"avirynkov.dev","short_name":"avirynkov","start_url":"/","background_color":"#FFF","theme_color":"#0da5b1","display":"standalone","icon":"./static/images/group.png","icons":[{"src":"./static/images/group.png","sizes":"512x512","type":"image/png"},{"src":"./static/images/android-icon-36x36.png","sizes":"36x36","type":"image/png","density":"0.75"},{"src":"static/images/android-icon-48x48.png","sizes":"48x48","type":"image/png","density":"1.0"},{"src":"static/images/android-icon-72x72.png","sizes":"72x72","type":"image/png","density":"1.5"},{"src":"static/images/android-icon-96x96.png","sizes":"96x96","type":"image/png","density":"2.0"},{"src":"static/images/android-icon-144x144.png","sizes":"144x144","type":"image/png","density":"3.0"},{"src":"static/images/android-icon-192x192.png","sizes":"192x192","type":"image/png","density":"4.0"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b7a001ecd9d99d8cc80541586c368cfc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
