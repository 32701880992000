import React, { Component } from 'react';

const defaultState = {
    dark: true,
    toggleDark: () => {
    },
};

const GlobalThemeContext = React.createContext(defaultState);

class GlobalThemeProvider extends Component {
    constructor(props) {
        super(props);

        this.state = { ...defaultState };
    }

    componentDidMount() {
        let themePreferences = localStorage.getItem('themePreferences');

        if (themePreferences !== null) {
            themePreferences = JSON.parse(themePreferences);
            this.setState({
                dark: themePreferences.isDarkModeActive
            })
        }

    }


    toggleDark = () => {
        let dark = !this.state.dark;
        this.setState({ dark }, () => {
            localStorage.setItem('themePreferences', JSON.stringify({ isDarkModeActive: this.state.dark }));
        });
    };

    render() {
        const { children } = this.props;
        const { dark } = this.state;
        return (
            <GlobalThemeContext.Provider
                value={{
                    dark,
                    toggleDark: this.toggleDark,
                }}
            >
                {children}
            </GlobalThemeContext.Provider>
        )
    }
}

export default GlobalThemeContext;
export { GlobalThemeProvider };
